import { useRouter } from 'next/router';
import { merge, sum } from 'lodash-es';
import { Card, CardHeader, CardProps, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { InventoryAvailabilityFilter } from 'src/api';
import { ReactApexChart, useBaseChartOptions } from 'src/components/chart';
import { PATH_APP } from 'src/paths';
import { fCurrency, fPercent } from 'src/utils/formatNumber';

const CHART_HEIGHT = 400;
const LEGEND_HEIGHT = 80;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    overflow: 'hidden',
  },
  '.apexcharts-pie-area': {
    cursor: 'pointer',
  },
}));

interface InventoryDonutProps extends CardProps {
  title?: string;
  subheader?: string;
  chartData: {
    label: string;
    value: number;
  }[];
  capacityUtilization: number;
  chartColors?: string[];
}

export function InventoryDonut({
  title,
  subheader,
  chartData,
  chartColors,
  capacityUtilization,
  sx,
  ...other
}: InventoryDonutProps) {
  const theme = useTheme();
  const router = useRouter();

  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(useBaseChartOptions(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fCurrency(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '130%',
          labels: {
            value: {
              formatter: (val: number | string) => fCurrency(val),
            },
            total: {
              formatter: (w: { globals: { seriesTotals: number[] } }) =>
                fCurrency(sum(w.globals.seriesTotals)),
              label: 'Inventarwert',
            },
          },
        },
      },
    },
  });

  return (
    <Card
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...sx,
      }}
      {...other}
    >
      <CardHeader title={title} subheader={subheader} />

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          type="donut"
          series={chartSeries}
          options={chartOptions}
          height={280}
          events={{
            dataPointSelection: (_event: any, _chartContext: any, config: any) => {
              const { label } = chartData[config.dataPointIndex];

              if (label === 'Verfügbar') {
                router.push({
                  pathname: PATH_APP.inventory.root,
                  query: { availability: InventoryAvailabilityFilter.Available },
                });
              } else if (label === 'Verkauft') {
                router.push({
                  pathname: PATH_APP.inventory.root,
                  query: { availability: InventoryAvailabilityFilter.Sold },
                });
              } else {
                router.push({
                  pathname: PATH_APP.inventory.root,
                });
              }
            },
          }}
        />
        <Stack
          sx={{
            marginTop: -10,
            direction: 'column',
            alignItems: 'center',
            fontFamily: 'CircularStd, sans-serif',
            fontWeight: 'bold',
            color: '#637381',
            fontSize: 13,
          }}
        >
          <p>Auslastung</p>
          <p>{fPercent(capacityUtilization)}</p>
        </Stack>
      </ChartWrapperStyle>
    </Card>
  );
}
