import { Box, Button, ButtonProps } from '@mui/material';
import { useMyAccountQuery } from 'src/api';
import { Iconify } from './Iconify';

export function ExportButton(buttonProps: ButtonProps) {
  const hasExportsEnabled = useMyAccountQuery().data?.myAccount?.exports.enabled;

  if (!hasExportsEnabled) return;

  return (
    <Box textAlign="right">
      <Button
        startIcon={<Iconify icon="eva:cloud-download-outline" />}
        size="small"
        color="primary"
        {...buttonProps}
      >
        Als Excel-Tabelle herunterladen
      </Button>
    </Box>
  );
}
