import { useState } from 'react';
import { Grid2, Stack } from '@mui/material';
import { League, Season } from 'src/api';
import { LeaguesSelectSmall } from 'src/components/app/LeaguesSelectSmall';
import { SeasonsSelectSmall } from 'src/components/app/SeasonsSelectSmall';
import { ExportButton } from 'src/components/ExportButton';
import { useExportDashboardToExcel } from './dashboard-export';
import { DashboardDiscount } from './DashboardDiscount';
import { DashboardForecast } from './DashboardForecast';
import { DashboardInventory } from './DashboardInventory';
import { DashboardKeyRight } from './DashboardKeyRight';
import { DashboardOffer } from './DashboardOffer';
import { DashboardPartnerAndContracts } from './DashboardPartner';

export interface DashboardParams {
  seasonId: string;
  leagueId: string;
}

export function Dashboard() {
  const [seasonId, setSeasonId] = useState('');
  const [leagueId, setLeagueId] = useState('');
  const exportToExcel = useExportDashboardToExcel(seasonId, leagueId);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={3}>
        <ExportButton onClick={exportToExcel} sx={{ ml: 2 }} />
        <SeasonsSelectSmall value={seasonId} onChange={(season: Season) => setSeasonId(season.id)} />
        <LeaguesSelectSmall value={leagueId} onChange={(league: League) => setLeagueId(league.id)} />
      </Stack>

      {leagueId && seasonId && (
        <Grid2 container spacing={3} pt={3} alignItems="stretch">
          <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
            <DashboardForecast seasonId={seasonId} leagueId={leagueId} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 2 }}>
            <DashboardDiscount seasonId={seasonId} leagueId={leagueId} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
            <DashboardInventory seasonId={seasonId} leagueId={leagueId} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 2 }}>
            <DashboardPartnerAndContracts seasonId={seasonId} leagueId={leagueId} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
            <DashboardOffer seasonId={seasonId} leagueId={leagueId} />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 8 }}>
            <DashboardKeyRight seasonId={seasonId} leagueId={leagueId} />
          </Grid2>
        </Grid2>
      )}
    </>
  );
}
