import Head from 'next/head';
import { Container } from '@mui/material';
import { useSettings } from 'src/contexts/SettingsContext';
import { Layout } from 'src/layout';
import { Dashboard } from 'src/sections/dashboard/Dashboard';

DashboardPage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
};

export default function DashboardPage() {
  const { themeStretch } = useSettings();

  return (
    <>
      <Head>
        <title>Dashboard | SponsoRights</title>
      </Head>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Dashboard />
      </Container>
    </>
  );
}
