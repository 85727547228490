import { ReactNode } from 'react';
import type { Cell, Column, Row, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { User } from 'src/api';
import { Translate } from 'src/locales/locale.types';
import { fDate, fTime } from './formatTime';

export const toPercent = (number = 0) => +number.toFixed(4);
export const shorten = (number = 0) => (number % 1 !== 0 ? +number.toFixed(2) : number);

export type UserMap = Record<string, User>;

export interface ExportColumn<T> {
  title: ReactNode;
  align?: ColumnAlignment;
  numFormat?: NumFormat;
  renderItem?: (item: T, translate: Translate) => ReactNode | Date;
}

export type ColumnAlignment = 'left' | 'center' | 'right';
export type NumFormat = 'integer' | 'number' | 'price' | 'percent' | 'date' | 'datetime' | 'text';

export const formatMap: Record<NumFormat, string> = {
  integer: '#0',
  number: '#,##0.00',
  price: '#,##0.00 "€";[Red]-#,##0.00 "€"',
  percent: '0.0"%"',
  date: 'dd.mm.yyyy',
  datetime: 'dd.mm.yyyy HH:mm',
  text: '@',
};

export function applyNumFormat(
  columnOrRowOrCell: Partial<Column> | Partial<Row> | Partial<Cell>,
  numFormat?: NumFormat,
) {
  if (numFormat) {
    columnOrRowOrCell.numFmt = formatMap[numFormat];
  }
}

export function formatHeading(row: Row) {
  row.font = { bold: true, size: 16 };
  row.alignment = { vertical: 'middle' };
  row.height = 24;
  row.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFDFE3E8' }, // Background color #dfe3e8
    };
  });
  return row;
}

export function formatSubHeading(row: Row) {
  row.font = { bold: true, size: 14 };
  row.alignment = { vertical: 'middle' };
  row.height = 20;
  row.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF6F7F8' }, // Background color #f6f7f8
    };
  });
  return row;
}

export function formatText(row: Row, bold = false) {
  row.font = { bold, size: 14 };
  row.alignment = { vertical: 'middle' };
  row.height = 20;
  return row;
}

export function formatColumn(
  column: Partial<Column>,
  layout?: { align?: ColumnAlignment; numFormat?: NumFormat },
) {
  const horizontal = layout?.align ?? (layout?.numFormat && 'right') ?? 'left';
  column.alignment = { vertical: 'middle', horizontal };
  applyNumFormat(column, layout?.numFormat);
  autoSizeColumn(column);
}

export function formatColumns(
  worksheet: Worksheet,
  layout: { align?: ColumnAlignment; numFormat?: NumFormat }[],
) {
  worksheet.columns.forEach((column, index) => {
    formatColumn(column, layout[index]);
  });
}

export function autoSizeColumn(column: Partial<Column>) {
  const offset = column.numFmt && column.numFmt !== formatMap.date ? 6 : 3; // adjust for formatted prices
  let maxLength = offset;

  column.eachCell?.({ includeEmpty: true }, (cell) => {
    if (cell.value instanceof Date) return; // ignore dates as they are below the maxLength

    let length = 0;

    if (cell.value) {
      if (typeof cell.value === 'number') {
        cell.value = shorten(cell.value); // handle cases like 1.00000000001
      }
      length = cell.value.toString().length;
      if (column.numFmt === formatMap.price) length += 1;
    }

    length += offset;

    if (cell.font?.size) {
      length *= ((cell.font.size - 10) / 3 + 10) / 10;
    }

    if (length > maxLength) {
      maxLength = length;
    }
  });

  column.width = Math.min(maxLength, 50);
}

export async function setUpWorkbook() {
  const ExcelJS = await import('exceljs');
  return new ExcelJS.Workbook();
}

export function addWorksheet(workbook: Workbook, sheetName = 'Excel-Export', xSplit = 0, ySplit = 0) {
  const worksheet = workbook.addWorksheet(sheetName);

  // show the collapse icon on the category row
  worksheet.properties.outlineProperties = {
    summaryBelow: false,
    summaryRight: false,
  };

  if (xSplit || ySplit) {
    // freenze header row and first column
    worksheet.views = [{ state: 'frozen', xSplit, ySplit }];
  }
  return worksheet;
}

export async function downloadSheet(workbook: Workbook, filename?: string) {
  const buffer = await workbook.xlsx.writeBuffer();
  const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const blob = new Blob([buffer], { type });
  filename ??= workbook.worksheets[0]?.name ?? 'SponsoRights-Export';
  saveAs(blob, `${filename}.xlsx`);
}

export function addExportMetaDataLine(
  worksheet: Worksheet,
  filterString?: string,
  filterPrefix = 'Filter: ',
) {
  worksheet.addRow(['']);
  return function finish() {
    const now = new Date();
    const filterRow = worksheet.getRow(1);
    filterRow.values = [
      `Export vom ${fDate(now)} um ${fTime(now)}`,
      filterString ? `${filterPrefix}${filterString}` : null,
    ].filter(Boolean);
    formatText(filterRow);
    // worksheet.mergeCells(1, 1, 1, 4);
    worksheet.mergeCells(1, filterString ? 2 : 1, 1, worksheet.getRow(2).cellCount);
  };
}
