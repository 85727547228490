import { useTheme } from '@mui/material/styles';
import { useDashboardInventoryQuery } from 'src/api';
import { DashboardParams } from './Dashboard';
import { InventoryDonut } from './InventoryDonut';

export function DashboardInventory({ seasonId, leagueId }: DashboardParams) {
  const theme = useTheme();

  const { data } = useDashboardInventoryQuery({ variables: { seasonId, leagueId } });

  if (!data) return null;

  return (
    <InventoryDonut
      variant="outlined"
      title="Inventar"
      capacityUtilization={data.dashboardInventory.stats.capacityUtilization ?? 0}
      chartColors={[theme.palette.primary.darker, theme.palette.primary.main, theme.palette.primary.dark]}
      chartData={[
        { label: 'Verkauft', value: data.dashboardInventory.stats.soldValue ?? 0 },
        { label: 'Verfügbar', value: data.dashboardInventory.stats.availableValue ?? 0 },
        { label: 'Geblockt', value: data.dashboardInventory.stats.blockedValue ?? 0 },
      ]}
      sx={{ height: '100%' }}
    />
  );
}
