import { format, formatDistanceToNow } from 'date-fns';

const fallback = '–';

export function fDate(date: Date | string | number, pattern = 'dd.MM.yy') {
  return date ? format(new Date(date), pattern) : fallback;
}

export function fTime(date: Date | string | number, pattern = 'HH:mm') {
  return fDate(date, pattern);
}

export function fDayMonth(date: Date | string | number, pattern = 'd.M.') {
  return fDate(date, pattern);
}

export function fToNow(date: Date | string | number) {
  return date ? formatDistanceToNow(new Date(date), { addSuffix: true }) : fallback;
}
