import { merge } from 'lodash-es';
import { styled, SxProps, useTheme } from '@mui/material/styles';
import { ReactApexChart, useBaseChartOptions } from 'src/components/chart';
import { fCurrency } from 'src/utils/formatNumber';

const CHART_HEIGHT = 400;
const LEGEND_HEIGHT = 80;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    overflow: 'hidden',
  },
  '.apexcharts-pie-area': {
    cursor: 'pointer',
  },
}));

interface TopCategoryDonutProps {
  chartData: {
    label: string;
    value: number;
  }[];
  chartColors?: string[];
  sx?: SxProps;
}

export function TopCategoryDonut({ chartData, chartColors, sx }: TopCategoryDonutProps) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(useBaseChartOptions(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fCurrency(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '130%',
          labels: {
            value: {
              formatter: (val: number | string) => fCurrency(val),
            },
            total: {
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fCurrency(sum);
              },
              label: 'Erlös',
            },
          },
        },
      },
    },
  });

  return (
    <ChartWrapperStyle dir="ltr" sx={sx}>
      <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={280} />
    </ChartWrapperStyle>
  );
}
